import React, { useState } from "react";
import { FormMessage } from "./FormMessage";
import axios from "axios";
import PhoneInput from "react-phone-number-input/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useForm } from "../hooks/useForm";
import "../assets/css/form.css";

export const Form = () => {
  const initialForm = {
    fullName: "",
    email: "",
  };

  const [formValues, handleInputChange, reset] = useForm(initialForm); // Hook para uso del custom hook useForm
  const [correctValidation, setCorrectValidation] = useState(0);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [popup, setPopup] = useState("popup popuptext");
  const [valueCheck, setValueCheck] = useState(false);
  const [loading, setLoading] = useState(false);

  // Tomo valores de la query
  const utms = window.location.search.substring(1).split("&");

  const utm_source = utms[0] ? utms[0].split("=").pop() : "Organic";
  const utm_medium = utms[1] ? utms[1].split("=").pop() : "Organic";
  const utm_campaign = utms[2] ? utms[2].split("=").pop() : "Organic";
  const utm_term = utms[3] ? utms[3].split("=").pop() : "Organic";
  const utm_content = utms[4] ? utms[4].split("=").pop() : "Organic";

  const handleSubmit = async (e) => {
    e.preventDefault(); // Evito que se recargue la pagina
    setLoading(true);

    // Verifico si el email existe.
    const email = await axios({
      method: "POST",
      url: "mail-validator.php",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email: formValues.email,
      },
    })
      .then((response) => {
        if (response.data.validate === true) {
          return true;
        } else {
          setCorrectValidation(3);
          setLoading(false);
          setTimeout(() => {
            setCorrectValidation(0);
          }, 4000);
          return false;
        }
      })
      .catch((err) => {
        setCorrectValidation(3);
        setLoading(false);
        setTimeout(() => {
          setCorrectValidation(0);
        }, 4000);

        return false;
      });

    let lead = {
      name: formValues.fullName,
      phone: phoneNumber,
      whatsapp: phoneNumber,
      email: formValues.email,
      utmSource: utm_source,
      leadProduct: "ADT_AR",
      leadSource: "MailC",
      utmMedium: utm_medium,
      utmCampaign: utm_campaign,
      utmTerm: utm_term,
      utmContent: utm_content,
      state: "",
    };

    // Si todo esta correcto, lo envio
    if (
      formValues.fullName &&
      isValidPhoneNumber(phoneNumber) &&
      email &&
      valueCheck
    ) {
      await axios({
        method: "POST",
        url: "processor.php",
        headers: {
          "Content-Type": "application/json",
        },
        data: lead,
      })
        .then((res) => {
          if (res.data.includes("success")) {
            setCorrectValidation(1); // Seteo la variable en 1 para que en la pagina se muestre el mensaje de "Uno de nuestros representantes lo contactará a la brevedad."
            setLoading(false);
          } else {
            setCorrectValidation(5);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.warn(err);
          setCorrectValidation(5); // "A ocurrido un error inesperado"
          setLoading(false);
        });

      setTimeout(() => {
        setCorrectValidation(0);
      }, 4000);
      reset();
      setValueCheck(!valueCheck);
      setPhoneNumber();
      return;
    }

    // Verifico si el teléfono es válido, sino, aparece mensaje de error
    if (!isValidPhoneNumber(phoneNumber)) {
      setCorrectValidation(2);
      setLoading(false);
      setTimeout(() => {
        setCorrectValidation(0);
      }, 4000);
      return;
    }
  };

  const handlerPopup = () => {
    popup === "popup popuptext"
      ? setPopup("popup popuptext-show")
      : setPopup("popup popuptext");
  };

  return (
    <div className="form">
      <div className="message">
        <FormMessage correctValidation={correctValidation} />
      </div>

      <h2>
        ¿Querés más info?
        <br />
        Dejá tus datos y te asesoramos
      </h2>
      <h3>Si preferís podés llamar al 0810 122 0305</h3>
      <form className="contact-form" onSubmit={handleSubmit}>
        <label htmlFor="fullName"> Nombre y apellido *</label>
        <input
          type="text"
          name="fullName"
          id="fullName"
          value={formValues.fullName}
          onChange={handleInputChange}
          required
        />
        <label htmlFor="phoneNumber"> Teléfono de contacto *</label>
        <PhoneInput
          defaultCountry="AR"
          name="phoneNumber"
          value={phoneNumber}
          onChange={setPhoneNumber}
          required
        />

        <label htmlFor="email"> E-mail *</label>
        <input
          type="email"
          name="email"
          id="email"
          value={formValues.email}
          onChange={handleInputChange}
          required
        />

        <div className="checkForm">
          <div className="conditions" onClick={handlerPopup}>
            <span htmlFor="checkbox" className="checkLabel">
              Acepto términos y condiciones
            </span>
          </div>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="check"
              checked={valueCheck}
              value={valueCheck}
              onChange={() => setValueCheck(!valueCheck)}
              required
            />
            <span id="popup" className={popup} onClick={handlerPopup}>
            TÉRMINOS Y CONDICIONES - SERVICIO DE MONITOREO DE ALARMAS

Duración del contrato: El contrato será por tiempo indeterminado.
Baja del servicio: Puedes solicitar la baja del servicio en www.adt.com.ar clicando en el botón correspondiente.
Equipos: El equipo se entrega en comodato. Al finalizar el contrato, deberás devolverlo a ADT dentro de los 10 días hábiles.
Descripción del servicio: ADT ofrece un servicio de seguridad electrónica. Una Central de Monitoreo procesa y analiza las señales enviadas por el equipo instalado en el domicilio monitoreado.
Requisitos de conectividad: Sí, debes mantener tu línea de teléfono y/o internet activa para que funcione el servicio, ya que los equipos requieren conexión a internet o a una línea telefónica según el modelo.
Responsabilidad por daños: El servicio que brinda ADT no incluye seguro contra robo, ni de guarda y/o custodia ante cualquier evento ocurrido en el domicilio monitoreado.
Pago: Si no pagas a tiempo, ADT podrá cobrar intereses desde la fecha de vencimiento de la factura impaga, gastos de gestión para la cobranza e informar sobre la deuda a empresas de riesgo crediticio.
Procedimiento ante alarmas: ADT aplica el procedimiento de emergencia establecido.

Para más información, contacta a ADT a través de:

Sitio web: www.adt.com.ar
Soporte: adtargentina.zendesk.com
Email: ar.clientesadt@tycoint.com
Teléfono: +549.116.443.0751
            </span>
          </div>
        </div>
        
        {loading ? (
          <button type="submit" disabled>
            <span
              className="spinner-border spinner-border-md"
              role="status"
              aria-hidden="true"
            ></span>
            {"      "}
            Cargando...
          </button>
        ) : (
          <button type="submit" value="Solicitar asesoramiento">
            Solicitar asesoramiento
          </button>
        )}
      </form>
    </div>
  );
};
